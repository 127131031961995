import * as React from 'react';
import { AppBar, Toolbar, Typography, withStyles, createStyles, Grid, Button } from '@material-ui/core';
// import color from '@material-ui/core/colors/blue';
// import Logo from './Logo';

const styles = (theme) => createStyles({
    main: {
        //backgroundColor: color[500],
        position: "absolute",
        top: 0,
        bottom: 0,
        width: "100%",
    },
    item: {
    }
});

class Login extends React.Component<any>{

    gotoLogin = () => location.href = `/Account/SingleSignOn?redirectTo=${location.pathname}`;

    render() {

        const { classes } = this.props;

        return (
            <React.Fragment>
                <AppBar style={{ background: '#a3c1d6' }}>
                    <Toolbar>
                        <Typography variant="h6" color="inherit">Software għall-Moniteraġġ u l-Evalwazzjoni tal-Implimentazzjoni (MEIS)</Typography>
                    </Toolbar>
                </AppBar>
                <Grid container className={classes.main} justify="center" alignItems="center" style={{ background: '#74a2c1' }}>
                    <Grid container item xs direction="column" justify="center" alignItems="center" spacing={32}>
                        <Grid item xs>
                            {/* <img style={{border:"1px solid",padding:"0 100px",height:"25%"}}/> */}
                        </Grid>
                        <Grid item xs>
                            <Button variant="contained" color="inherit" style={{ margin: "auto" }} onClick={this.gotoLogin.bind(this)}>Login</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

}

export default withStyles(styles)(Login);