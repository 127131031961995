import * as React from "react";
import { Route } from "react-router-dom";
import Layout from "./Layout";
// import { Paper } from '@material-ui/core';
import * as Loadable from 'react-loadable'
// import JsonUtils from "../Utils/Json/Json";
import Login from "../Pages/Login/Login";
import publicPath from "../../publicPath";

publicPath("/");

const MeasureMonitor = Loadable({
    loader: () => import(/* webpackChunkName: "MeasureMonitor" *//* webpackPreload: true */"../Pages/MeasureMonitor/MeasureMonitor"),
    loading: (() => <div style={{
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        margin: "12px",
        padding: "12px"
    }}>Is-sistema qed tillowdja...</div>)
});

// const Unauthorized = Loadable({
//     loader: () => import(/* webpackChunkName: "Unauthorized" *//* webpackPreload: true */"../Pages/Unauthorized/Unauthorized"),
//     loading: (() => <div style={{
//         fontFamily: "Roboto, Helvetica, Arial, sans-serif",
//         margin: "12px",
//         padding: "12px"
//     }}>Is-sistema qed tillowdja...</div>)
// });

interface AppVariables {
    CurrPageDrawerLinksContainer: HTMLElement,
    AppBarContainer: HTMLElement,
    DrawerOpen: boolean,
    LoginInfo: any
}

interface AppFunctions {
    OpenDrawer: Function
}

interface AppState {
    Variables: AppVariables,
    Functions: AppFunctions,
    self: any,
}

export default class App extends React.Component {

    static state: AppState = {
        Functions: {
            OpenDrawer: null
        },
        Variables: {
            CurrPageDrawerLinksContainer: null,
            AppBarContainer: null,
            DrawerOpen: null,
            LoginInfo: window["userInfo"]
        },
        self: null
    }

    state = {
        Language: ""
    }

    componentDidMount() {
        App.state.self = this;
        this.getLanguage();
    }

    getLanguage() {
        var Lang = localStorage.getItem('Language');
        if (Lang == null) {
            localStorage.setItem('Language', 'Maltese');
            this.setState({ Language: localStorage.getItem('Language') })
        } else {
            this.setState({ Language: localStorage.getItem('Language') })
        }
    }

    UpdateState(state) {
        this.setState(state);
        window["lastSession"] = JSON.stringify(this.state);
    }

    render() {
        return (
            <React.Fragment>
                {App.state.Variables.LoginInfo === undefined ? <Login /> :
                    <Layout Language={this.state.Language} setState={this.UpdateState.bind(this)} >
                        {/* <Route path="/" component={MeasureMonitor} /> */}
                        <Route path="/" render={(props) => <MeasureMonitor Language={this.state.Language} setState={this.UpdateState.bind(this)} {...props} />} />
                    </Layout>
                }
            </React.Fragment>
        );
    }

}