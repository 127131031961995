import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import * as ReactDOM from "react-dom";
import App from "./components/app/App";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true
    }, overrides: {
        MuiButton: {
            outlined: {
                margin: "8px"
            }
        }
    }
});

ReactDOM.render(
    <BrowserRouter>
        <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <App />
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    </BrowserRouter>,
    document.getElementById("React")
);