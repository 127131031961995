import * as React from 'react';
// import classNames from 'classnames';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
// import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
import HomeIcon from '@material-ui/icons/Home';
// import MailIcon from '@material-ui/icons/Mail';
import { Hidden, /* Paper, */ SwipeableDrawer } from '@material-ui/core';

import { Link } from 'react-router-dom';
import App from './App';

const drawerWidth = 240;

const styles = theme => createStyles({
  root: {
    display: 'flex',
    width: "100%"
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
      //paddingRight: "10px"
    },
    //color:"#FFFFFF"

  },
  drawerPaper: {
    width: drawerWidth,
    background: '#a3c1d6',
    //color:'#FFFFFF'
  },
  content: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  toolbar: theme.mixins.toolbar,
});

const links = [
  { text: "Home", to: "https://eformsdev.intra.gov.mt/EForms", icon: <HomeIcon /> }
];

interface props {
  Language: string,
  classes?: any,
  theme?: any,
  container?: any,
  setState: any
}

class Layout extends React.Component<props> {
  state = {
    open: false,
  };

  toggleDrawer = (open) => () => {
    this.setState({
      open: open,
    });
  };

  /* componentWillMount(){
    App.state.Functions.OpenDrawer=this.toggleDrawer.bind(this);
  } */

  componentDidMount() {
    App.state.Functions.OpenDrawer = this.toggleDrawer.bind(this);
  }

  componentWillUnmount() {
    App.state.Functions.OpenDrawer = null;
  }

  UpdateState(state) {
    this.props.setState(state);
    //window["lastSession"]=JSON.stringify(this.state);
  }

  DrawerLinksArray = () => {
    const distinct = (value, index, self) => self.indexOf(value) === index;

    var sidebarPageButtonsArray = [];

    const management = { text: "Maniġment", to: "Management/MeasureTypes" };
    const implementation = { text: "Implimentazzjoni", to: "Implementation/Select" };
    const evaluation = { text: "Evalwazzjoni", to: "Implementation/Select" };
    const search = { text: "Lista ta’ Miżuri", to: `Search/All` };
    const approval = { text: "Approvazzjonijiet", to: `Approvals` };
    const create = { text: "Oħloq Miżura", to: `Create/Details` };
    const documents = { text: "Dokumenti Utli", to: `Documents/Documents` };

    const userRoles = window["userInfo"].Properties.Roles || []

    if (userRoles.indexOf("OPM Admin") !== -1) {
      const reports = { text: "Rapporti", to: `Reports` };
      sidebarPageButtonsArray.push(management);
      sidebarPageButtonsArray.push(create);
      sidebarPageButtonsArray.push(evaluation);
      sidebarPageButtonsArray.push(reports);
      sidebarPageButtonsArray.push(search);
      sidebarPageButtonsArray.push(documents);
    }

    if (userRoles.indexOf("Permanent Secretary") !== -1) {
      const reports = { text: "Rapporti", to: `Reports/Implementation` };
      sidebarPageButtonsArray.push(implementation);
      sidebarPageButtonsArray.push(approval);
      sidebarPageButtonsArray.push(reports);
      sidebarPageButtonsArray.push(search);
      sidebarPageButtonsArray.push(documents);
    }
    if (userRoles.indexOf("Focal Point") !== -1) {
      const reports = { text: "Rapporti", to: `Reports/Implementation` };
      sidebarPageButtonsArray.push(implementation);
      sidebarPageButtonsArray.push(reports);
      sidebarPageButtonsArray.push(search);
      sidebarPageButtonsArray.push(documents);
    }
    if (userRoles.indexOf("Project Leader") !== -1) {
      sidebarPageButtonsArray.push(implementation);
      sidebarPageButtonsArray.push(search);
      sidebarPageButtonsArray.push(documents);
    }
    if (userRoles.indexOf("View-Only User") !== -1) {
      const reports = { text: "Rapporti", to: `Reports/Implementation` };
      sidebarPageButtonsArray.push(reports);
      sidebarPageButtonsArray.push(search);
    }

    return sidebarPageButtonsArray.filter(distinct);
  };

  render() {
    const { classes, theme } = this.props;

    const drawer = (
      <div style={{ backgroundColor: "#a3c1d6", textDecorationColor: "#ffffff" }}>
        <div className={classes.toolbar} style={{ backgroundColor: "#74a2c1", textDecorationColor: "#ffffff" }} />
        <Divider />
        <List onClick={() => this.toggleDrawer.bind(this, false)}>
          {this.DrawerLinksArray().map((link, index) => (
            //<!-- onClick={() => sessionStorage.removeItem("MeasureFilter")}> -->
            <Link to={`/${link.to}`} style={{ textDecoration: "none", backgroundColor: "#a3c1d6" }} key={index}> 
              <ListItem button key={link.text}>
                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: "16px" }}>{link.text}</Typography>} />
              </ListItem>
            </Link>
          ))}
        </List>
      </div>
    );

    return (
      <div className={classes.root}>
        <CssBaseline />
        <div ref={ref => { App.state.Variables.AppBarContainer = ref }} />
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden mdUp implementation="css">
            <SwipeableDrawer
              onOpen={this.toggleDrawer(true)}
              container={this.props.container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={this.state.open}
              onClose={this.toggleDrawer(false)}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {drawer}
            </SwipeableDrawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.props.children}
        </main>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Layout);